import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { slugy } from 'slugy'

const Updates = ({ data }) => (
  <Layout>
    <SEO
      title="Progress Updates"
      description="Follow along with the Holles' adoption story."
    />
    <div className="container">
      <div className="w-full max-w-2xl mx-auto py-12">
        <h1 className="font-bold text-4xl mb-8">Updates on Our Adoption</h1>
        <p className="text-xl text-gray-700">
          This is where we will post updates on our adoption progress. You can see the amounts paid in <span className="px-2 py-1 text-sm rounded bg-gray-700 text-white">Gray</span>.
        </p>
        {/* <p className="text-xl text-gray-700">
          <strong>Upcoming expenses</strong>: $7,000
        </p> */}

        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.frontmatter.title}>
            <Link className="no-underline hover:underline" to={`/updates/${slugy(node.frontmatter.title)}`}>
              <h3 className="text-2xl font-bold">{node.frontmatter.title}</h3>
            </Link>
            <div className="pb-2 text-gray-500">
              {node.frontmatter.date}

              {node.frontmatter.expenses &&
                node.frontmatter.expenses.map(e => <span className="px-2 py-1 ml-2 text-sm rounded bg-gray-700 text-white">${e}</span>)}
            </div>
            <p>{node.frontmatter.summary}</p>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark (sort: { fields: [frontmatter___sort], order: DESC }) {
      edges {
        node {
          frontmatter {
            date
            title
            summary
            expenses
          }
          html
        }
      }
    }
  }
`

export default Updates
